import React, { useRef } from "react";
import { chipImg, frameImg, frameVideo } from "../utils";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { animateWithGsap } from "../utils/animation";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const HowItWorks = () => {
  const videoRef = useRef();
  const handleVideoPlayPause = (action) =>
    action === "play" ? videoRef.current.play() : videoRef.current.pause();

  useGSAP(() => {
    // * chip animation
    animateWithGsap(
      "from",
      "#chip",
      {
        opacity: 0,
        scale: 2,
        duration: 1.3,
        ease: "power2.inOut",
      },
      {
        start: "top 90%",
        end: "bottom 35%",
        toggleActions: "play none none none",
      }
    );
    // * chip shimmer animation
    animateWithGsap(
      "to",
      "#chip-shimmer",
      {
        x: "500%",
        duration: 3,
        ease: "power2.inOut",
        repeat: -1,
      },
      { start: "top 90%", end: "bottom 40%", toggleActions: "play reverse play reverse" }
    );
    // * text animation
    animateWithGsap("to", ".g_fadeIn", {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power2.inOut",
    });
    // * video animation
    gsap.to(videoRef.current, {
      scrollTrigger: {
        trigger: videoRef.current,
        start: "top 90%",
        end: "bottom 40%",
        toggleActions: "play pause resume pause",
        onEnter: () => handleVideoPlayPause("play"),
        onLeave: () => handleVideoPlayPause("pause"),
        onEnterBack: () => handleVideoPlayPause("play"),
        onLeaveBack: () => handleVideoPlayPause("pause"),
      },
    });
  }, []);
  return (
    <section className="common-padding">
      <div className="screen-max-width">
        {/* Chip */}
        <div id="chip" className="flex-center w-full my-20 ">
          <div className="relative overflow-hidden">
            <img src={chipImg} alt="chip" width={180} height={180} />
            <div id="chip-shimmer" className="chip-shimmer"></div>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <h2 className="hiw-title">
            A17 Pro chip.
            <br /> A monster win for gaming.
          </h2>

          <p className="hiw-subtitle">
            It's here. The biggest redesign in the history of Apple GPUs.
          </p>
        </div>

        <div className="mt-10 md:mt-20 mb-14">
          <div className="relative h-full flex-center">
            <div className="overflow-hidden">
              <img src={frameImg} alt="frame" className="bg-transparent relative z-10" />
            </div>
            <div className="hiw-video">
              <video
                className="pointer-events-none"
                playsInline
                preload="none"
                muted
                autoPlay
                ref={videoRef}
              >
                <source src={frameVideo} type="video/mp4" />
              </video>
            </div>
          </div>
          <p className="text-gray font-semibold text-center mt-3">Honkai: Star Rail</p>
        </div>

        <div className="hiw-text-container">
          <div className="flex flex-1 justify-center flex-col">
            <p className="hiw-text g_fadeIn">
              A17 Pro is an entirely new class of iPhone chip that delivers our{" "}
              <span className="text-white">best graphic performance by far</span>.
            </p>

            <p className="hiw-text g_fadeIn">
              Mobile <span className="text-white">games will look and feel so immersive</span>, with
              incredibly detailed environments and characters.
            </p>
          </div>

          <div className="flex-1 flex justify-center flex-col g_fadeIn">
            <p className="hiw-text">New</p>
            <p className="hiw-bigtext">Pro-class GPU</p>
            <p className="hiw-text">with 6 cores</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
